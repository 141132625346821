.container-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1159px;
}

@media (max-width: 1275px) {
  .container-wrapper {
    max-width: 1050px;
  }
}

@media (max-width: 1150px) {
  .container-wrapper {
    max-width: 1000px;
  }
}

@media (max-width: 1055px) {
  .container-wrapper {
    max-width: 850px;
  }
}

@media (max-width: 900px) {
  .container-wrapper {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .container-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}